import Sidebar from "../../components/sidebar/Sidebar";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import './Setup.css';
import { ContactSupport, ErrorOutline, Link, PauseTwoTone, SyncTwoTone, TaskAlt } from "@mui/icons-material";
import { Button, Popover, notification,Spin } from "antd";
import { NavigateModal } from "../../components/modals/NavigateModal";
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { ApiContext} from '../../ApiContext';
export const Setup = () => {
    const { activeUser, setupDetails, loading } = useContext(ApiContext);
    const navigate = useNavigate()
    const [errorDetails, setErrorDetails] = useState()
    const [error, setError] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, message) => {
        api[type]({
            message: title,
            description: message,
        });
    };

    const handleLoginWithAmazon = async () => {
        try {
            const url = process.env.REACT_APP_BACKEND_URL + "/user/" + activeUser?.id + "/amazon/setup/continue";
            const response = await fetch(url, {
                headers: {
                    "accessToken": localStorage.getItem("accessToken")
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                throw new Error('Unable to start');
            } else {
                console.log('Response is OK');
                const responseBody = await response.json();
                window.location.href = responseBody.data;
            }
        } catch (error) {
            console.error('Error during start:', error);
        }

    }

    const handleSyncInitiate = async (e) => {
        e.preventDefault();
        try {
            const url = process.env.REACT_APP_BACKEND_URL + "/user/" + activeUser?.id + "/amazon/setup/sync";
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "accessToken": localStorage.getItem("accessToken"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });

            // Check if the response is OK
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                throw new Error(response.body);
            } else {
                console.log('Response is OK');
                openNotificationWithIcon('success', "Sync Initiated", "Please wait for some time, Have a coffee, come back and refresh")
                window.location.reload()
            }
        } catch (error) {
            openNotificationWithIcon('error', "Something went wrong", error.message)
            console.error('Error during start:', error);
        }
    }

    if (loading) {
        return(
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign:'center'
            }}>
                <Spin size="large" />
                <span style={{ marginTop: '8px' }}>Loading...</span> {/* Text directly below the spinner */}
            </div>
        )
    }


    const props = {
        name: 'file',
        action: process.env.REACT_APP_BACKEND_URL + "/user/" + activeUser?.id + "/skus/costsheet",
        headers: {
            "accessToken": localStorage.getItem("accessToken"),
        },
        showUploadList: false,
        onChange(info) {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <>
            {contextHolder}
            <div className="setuppage">
                <Sidebar activePage={"setup"} activeUserName={activeUser?.name} activeUserEmail={activeUser?.email} image="/logo.svg"></Sidebar>
                <div className="setupmain">
                    <div className="heading">
                        <h1 className="headingtitle">Setup</h1>
                        <Popover content={"Send an email at support@atmirror.in"} title="Support" trigger="click" placement="left">
                            <Button type="primary" size={"large"} icon={<ContactSupport />} iconPosition={'end'}>
                                <div className="text-wrapper-2">Contact Support</div>
                            </Button>
                        </Popover>
                    </div>

                    <div className="setupsection">
                        <div className="sectiondetails">
                            <h2 className="sectiondetailsheading">Amazon Account</h2>
                            <p className="sectiondetailsdesc">Connect with your amazon seller account to enable analytics</p>
                        </div>
                        <div className="sectionblock">
                            <div className="amazondetails">
                                <h6 className="sellerinfo">Seller Id : {setupDetails?.externalId ?? "Not linked yet"}</h6>
                                {setupDetails?.amazonLinkStatus ?
                                    {
                                        "STARTED": <Button type="primary" size={"large"} loading iconPosition={'end'}>
                                            <div className="text-wrapper-2">Started...</div>
                                        </Button>,
                                        "SUCCESS": <Button type="success" size={"large"} icon={<TaskAlt color="success" />} disabled iconPosition={'end'}>
                                            <div className="text-wrapper-2">Linked</div>
                                        </Button>,
                                        "ERROR": <Popover content={setupDetails?.message} title="Link Failed" trigger="click">
                                            <Button type="primary" danger size={"large"} icon={<ErrorOutline></ErrorOutline>} iconPosition={'end'}>
                                                <div className="text-wrapper-2">Failed</div>
                                            </Button>
                                        </Popover>,
                                        "REFRESH_TOKEN_EXPIRED": <Popover content={"Contact Support"} title="Refresh Token Expired" trigger="click">
                                            <Button type="primary" size={"large"} icon={<Link></Link>} iconPosition={'end'} onClick={handleLoginWithAmazon}>
                                                <div className="text-wrapper-2">Link</div>
                                            </Button>
                                        </Popover>
                                    }[setupDetails?.amazonLinkStatus]
                                    :
                                    <Button type="primary" size={"large"} icon={<Link></Link>} iconPosition={'end'} onClick={handleLoginWithAmazon}>
                                        <div className="text-wrapper-2">Link</div>
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="setupsection">
                        <div className="sectiondetails">
                            <h2 className="sectiondetailsheading">Data Sync</h2>
                            <p className="sectiondetailsdesc">Clicking on sync will initiate data sync process from 01/04/2024 to {new Date(new Date().getFullYear(),
                                new Date().getMonth(), new Date().getDate() - 7).toLocaleDateString()}</p>
                        </div>
                        <div className="sectionblock">
                            <div className="amazondetails">
                                <h6 className="sellerinfo">Synced upto : {setupDetails?.lastSyncedTime > 0 ? new Date(setupDetails?.lastSyncedTime).toLocaleString() : "Not synced yet"}</h6>
                                {setupDetails?.dataSyncStatus ?
                                    {
                                        "STARTED": <Button type="primary" size={"large"} loading iconPosition={'end'}>
                                            <div className="text-wrapper-2">Started...</div>
                                        </Button>,
                                        "IN_PROGRESS": <Button type="primary" size={"large"} loading iconPosition={'end'}>
                                            <div className="text-wrapper-2">Syncing...</div>
                                        </Button>,
                                        "SUCCESS": <Button type="success" size={"large"} icon={<TaskAlt color="success" />} disabled iconPosition={'end'}>
                                            <div className="text-wrapper-2">Synced</div>
                                        </Button>,
                                        "ERROR": <Popover content={setupDetails?.message} title="Sync Failed" trigger="click">
                                            <Button type="primary" danger size={"large"} icon={<ErrorOutline></ErrorOutline>} iconPosition={'end'}>
                                                <div className="text-wrapper-2">Failed</div>
                                            </Button>
                                        </Popover>,
                                        "PAUSED": <Popover content={"Contact Support"} title="Sync Paused" trigger="click">
                                            <Button type="primary" size={"large"} icon={<PauseTwoTone />} iconPosition={'end'} style={{ background: "orange" }}>
                                                <div className="text-wrapper-2">Paused</div>
                                            </Button>
                                        </Popover>
                                    }[setupDetails?.dataSyncStatus]
                                    :
                                    <Button type="primary" size={"large"} icon={<SyncTwoTone />} iconPosition={'end'} onClick={handleSyncInitiate}>
                                        <div className="text-wrapper-2">Sync</div>
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="setupsection">
                        <div className="sectiondetails">
                            <h2 className="sectiondetailsheading">Cost Sheet</h2>
                            <p className="sectiondetailsdesc">Upload your cost sheet here in this <a href="https://docs.google.com/spreadsheets/d/18gd2G-D6U8XryDoM7INVBGtui7prkKs7/export?format=xlsx" download>format</a>, we only support excel file at the moment
                            </p>
                        </div>
                        <div className="sectionblock">
                            <div className="amazondetails">
                                <h6 className="sellerinfo">Updated at : {setupDetails?.costSheetUpdatedAt > 0 ? new Date(setupDetails?.costSheetUpdatedAt).toLocaleString() : "Not uploaded yet"}</h6>
                                <div className="text-wrapper-2">
                                    <Upload {...props}>
                                        <Button type="primary" size={"large"} icon={<UploadOutlined />} iconPosition={'end'}>
                                            <div className="text-wrapper-2">Upload</div>
                                        </Button>
                                        {/* <Button icon={<UploadOutlined />}>Upload</Button> */}
                                    </Upload></div>
                            </div>
                        </div>
                    </div>
                </div>
                {error && errorDetails?.title &&
                    <div>
                        <NavigateModal
                            title={errorDetails.title}
                            body={errorDetails.message}
                            path={errorDetails.path}
                            buttonText={errorDetails.buttonText}
                        />
                    </div>
                }
            </div>
        </>
    );

}