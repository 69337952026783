import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { NavigateModal } from "../../components/modals/NavigateModal";
import { DataGrid, gridClasses, useGridApiContext, useGridSelector, GridPagination, gridPageCountSelector } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import moment from 'moment';
import { CModal } from "@coreui/react";
import { Button } from "antd";


function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
        <MuiPagination
            color="primary"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}
        />
    );
}

function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const CustomButtonComponent = ({ onDelete }) => {
    const [showModal, setShowModal] = useState(false);
    const [reason, setReason] = useState("");

    const handleConfirmDelete = () => {
        if (!reason.trim()) {
            alert("Please write a reason."); // Alert if textarea is empty
            return;
        }
        onDelete(reason); // Pass the reason to the onDelete function
        setShowModal(false);
        setReason(""); // Reset reason after submission
    };

    const handleButtonClick = (event) => {
        event.stopPropagation(); // Prevents the row selection
        setShowModal(true); // Show modal
    };

    return (
        <>
            <Button type="primary" onClick={handleButtonClick}>Mark As Incorrect</Button>
            {showModal && (
                <CModal
                    alignment="center"
                    backdrop="static"
                    scrollable
                    visible={showModal}
                    onClose={() => setShowModal(false)}
                >
                    <div style={{ padding: "20px" }}>
                        <h5>Not Suspicious?</h5>
                        <p>Please provide a reason, this will help us improve</p>
                        <textarea
                            rows="4"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            style={{ width: "100%", marginBottom: "10px" }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <Button  onClick={() => setShowModal(false)}>Cancel</Button>
                            <Button type="primary" onClick={handleConfirmDelete}>Confirm</Button>
                        </div>
                    </div>
                </CModal>
            )}
        </>
    );
};

const getQueryParams = (params) => ({
    size: params.pagination?.pageSize,
    page: params.pagination?.page,
    sortField: params.sorting?.field,
    sortOrder: params.sorting?.sort,
    filterField: params.filters?.field,
    filterOperator: params.filters?.operator,
    filterValue: params.filters?.value,
    startDate: params.dates?.start,
    endDate: params.dates?.end
});

export const SuspectedTable = ({ userId, accessToken, dates }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorDetails, setErrorDetails] = useState();
    const [error, setError] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [tableParams, setTableParams] = useState({
        pagination: {
            page: 0,
            pageSize: 20,
        },
        sorting: {
            field: "orderPlacedTime",
            sort: "desc",
        },
        filters: {
            field: "orderId",
            operator: "isNotEmpty",
            value: ""
        },
        dates: {
            start: moment(new Date(String(dates[0]))).format('YYYY-MM-DD'),
            end: moment(new Date(String(dates[1]))).format('YYYY-MM-DD'),
        }
    });

    const columns = [
        {
            renderHeader: (params) => (
                <strong>{'Order ID'}</strong>
            ),
            field: "orderId",
            headerName: 'Order Id',
            flex: 1,
            type: 'string',
            align: 'center',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: "orderPlacedTime",
            headerName: 'Order Placed Time',
            flex: 1,
            sortable: true,
            type: 'string',
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>{'Order Placed Time'}</strong>
            ),
            valueFormatter: (params) => (new Date(params).toLocaleString())
        },
        {
            field: "orderAmount",
            headerName: 'Amount',
            flex: 1,
            sortable: true,
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>{'Amount'}</strong>
            ),
        },
        {
            renderHeader: (params) => (
                <strong>{'Potential Value'}</strong>
            ),
            field: "potentialValue",
            headerName: 'Potential Value',
            flex: 1,
            sortable: false,
            type: 'number',
            align: 'center',
            headerAlign: 'center',
        },
        {
            renderHeader: (params) => (
                <strong>{'Suspicious Reason'}</strong>
            ),
            field: "suspiciousReason",
            headerName: 'Suspicious Reason',
            sortable: false,
            flex: 1,
            type: 'string',
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: "actions",
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderHeader: () => (
                <strong>{'Actions'}</strong>
            ),
            renderCell: (params) => (
                <CustomButtonComponent onDelete={() => handleDelete(params.row.id)} />
            ),
        }

    ];

    useEffect(() => {
        if (dates && dates[0] && dates[1]) {
            setTableParams(prev => ({
                ...prev,
                dates: {
                    start: moment(new Date(String(dates[0]))).format('YYYY-MM-DD'),
                    end: moment(new Date(String(dates[1]))).format('YYYY-MM-DD'),
                }
            }));
        }
    }, [dates]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (accessToken && userId && tableParams.dates.start) {
                fetchData(accessToken, userId);
            }
        }, 300); // Add small delay to prevent rapid consecutive calls

        return () => clearTimeout(debounceTimer);
    }, [tableParams, accessToken, userId]); // Single dependency on tableParams object

    const fetchData = async (accessToken, userId) => {
        setLoading(true);
        try {
            const url = process.env.REACT_APP_BACKEND_URL + `/user/` + userId + `/amazon/data/suspected?${qs.stringify(getQueryParams(tableParams))}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": accessToken
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("userId");
                    setError(true);
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    });
                }
                throw new Error('Unable to get status');
            } else {
                const responseBody = await response.json();
                setData(data)
                setLoading(false);
                setTotalRows(responseBody.totalElements);
            }
        } catch (error) {
            console.error('Error during get setup status:', error);
        }
    };

    const handleDelete = async (id, reason) => {
        try {
            const url = process.env.REACT_APP_BACKEND_URL + `/user/` + userId + `/amazon/data/suspected/${id}`;
            const response = await fetch(url, {
                method: "PATCH",
                headers: {
                    "accessToken": accessToken
                },
                body: {
                    incorrectReason: reason
                }
            });

            if (response.ok) {
                setData(prevData => prevData.filter(row => row.id !== id));
                setTotalRows(prevTotal => prevTotal - 1);
            } else {
                console.error("Failed to delete the record");
            }
        } catch (error) {
            console.error("Error during deletion:", error);
        }
    };

    const handlePagination = (e) => {
        setTableParams(prev => ({
            ...prev,
            pagination: {
                page: e.page,
                pageSize: e.pageSize
            }
        }));
    };

    const handleSortModelChange = React.useCallback((sortModel) => {
        setTableParams(prev => ({
            ...prev,
            sorting: sortModel[0] 
                ? {
                    field: sortModel[0].field,
                    sort: sortModel[0].sort
                }
                : {
                    field: "totalRevenue",
                    sort: "desc"
                }
        }));
    }, []);

    const handleFilterChange = React.useCallback((filterModel) => {
        setTableParams(prev => ({
            ...prev,
            filters: filterModel.items[0]
                ? {
                    field: filterModel.items[0].field,
                    operator: filterModel.items[0].operator,
                    value: filterModel.items[0].value
                }
                : {
                    field: "orderId",
                    operator: "isNotEmpty",
                    value: ""
                }
        }));
    }, []);

    return (
        <div>
            <div style={{ height: `calc(100vh - 150px)`, width: '100%' }}>
                <DataGrid
                    rowHeight={45}
                    rows={data}
                    rowCount={totalRows}
                    paginationModel={tableParams.pagination}
                    paginationMode="server"
                    onPaginationModelChange={handlePagination}
                    slots={{
                        pagination: CustomPagination,
                    }}
                    columns={columns}
                    loading={loading}
                    pageSizeOptions={[10, 20, 50, 100]}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    filterMode="server"
                    onFilterModelChange={handleFilterChange}
                    disableColumnSelector
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'orderPlacedTime', sort: 'desc' }],
                        },
                    }}
                    sx={{
                        [`& .${gridClasses.menuIcon}`]: {
                            visibility: "visible",
                            width: "auto"
                        }
                    }}
                />
            </div>
            {error && errorDetails?.title &&
                <div>
                    <NavigateModal
                        title={errorDetails.title}
                        body={errorDetails.message}
                        path={errorDetails.path}
                        buttonText={errorDetails.buttonText}
                    />
                </div>
            }
        </div>
    );
}
