import { useEffect, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import './Home.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import { notification, Spin } from "antd"
import { NavigateModal } from "../../components/modals/NavigateModal";
import { ApiContext} from '../../ApiContext';
import { defaultDateRange, predefinedRanges } from "../../components/DatePicker";
import { DateRangePicker, Stack } from 'rsuite';
import { OrderAnalytics } from "./OrderAnaytics";
import moment from "moment";
import { CostAndMetrics } from "./CostAndMetrics";
export const Home = () => {
    const { activeUser, setupDetails, loading, setLoading, setActiveUser } = useContext(ApiContext);
    const [dates, setDates] = useState(defaultDateRange);
    const navigate = useNavigate()
    const [errorDetails, setErrorDetails] = useState()
    const [error, setError] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, message) => {
        api[type]({
            message: title,
            description: message,
        });
    };
    const [statInfo, setStatInfo] = useState()
    const [summaries, setSummaries] = useState()
    const [dataLoading, setDataLoading] = useState(false)
    const [costOverview, setCostOverview] = useState()
    const [skuStats, setSkuStats] = useState()
    const [suspiciousOrdersInfo, setSuspiciousOrdersInfo] = useState()


    const handleDateChange = (range) => {
        setDates(range)
        localStorage.setItem("daterange", range);
    };

    const handleShortcutDateChange = (shortcut, event) => {
        setDates(shortcut.value)
        localStorage.setItem("daterange", shortcut.value);
    }

    useEffect(() => {
        const storedRange = localStorage.getItem("daterange");
        if (storedRange) {
            const dateRange = storedRange.split(",").map(dateStr => dateStr ? new Date(dateStr) : null);
            setDates(dateRange);
        }
        if (!activeUser){
            fetchUser(localStorage.getItem("accessToken"))
        }
    }, []);

    const fetchUser = async (accessToken) => {
        try {
          const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/token", {
            headers: {
              accessToken
            }
          });
          if (!response.ok) {
            throw new Error("Error from fetchUser API");
          }
          const data = await response.json();
          if (data.status === "SUCCESS") {
            setActiveUser(data.data);
          } else {
            if (data.status === "ERROR") {
                localStorage.removeItem("accessToken")
                localStorage.removeItem("userId")
                setError(true)
                setErrorDetails({
                    title: "Session Expired",
                    message: "Please log in again, Click on logout to navigate to login screen",
                    buttonText: "Logout",
                    path: "/"
                })
            }
            throw new Error(data.data);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
    };

    useEffect(() => {
        if (activeUser && dates[0] && dates[1]){
            setDataLoading(true)
            fetchOrderStats(activeUser.id, dates)
            fetchCostOverview(activeUser.id, dates)
            fetchSkuStats(activeUser.id, dates)
        }
    }, [activeUser, dates])

    const fetchOrderStats = async (userId, dates) => {
        try {
            const start = moment(new Date(String(dates[0]))).format('YYYY-MM-DD')
            const end = moment(new Date(String(dates[1]))).format('YYYY-MM-DD')
            const url = process.env.REACT_APP_BACKEND_URL + "/user/" + userId + "/amazon/data/analytics/orders?startDate="+ start + "&endDate=" + end;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": localStorage.getItem("accessToken")
                }
            });

            // Check if the response is OK
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                throw new Error('Unable to get status');
            } else {
                console.log('Response is OK');
                const responseBody = await response.json();
                setStatInfo(responseBody.data.stats)
                setSummaries(responseBody.data.summaries)
                setDataLoading(false)
            }
        } catch (error) {
            console.error('Error during get setup status:', error);
        }
    }

    const fetchSkuStats = async (userId, dates) => {
        try {
            const start = moment(new Date(String(dates[0]))).format('YYYY-MM-DD')
            const end = moment(new Date(String(dates[1]))).format('YYYY-MM-DD')
            const url = process.env.REACT_APP_BACKEND_URL + "/user/" + userId + "/amazon/data/analytics/skus?startDate="+ start + "&endDate=" + end;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": localStorage.getItem("accessToken")
                }
            });

            // Check if the response is OK
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                throw new Error('Unable to get status');
            } else {
                console.log('Response is OK');
                const responseBody = await response.json();
                setSkuStats(responseBody.data)
                setDataLoading(false)
            }
        } catch (error) {
            console.error('Error during get setup status:', error);
        }
    }

    if (loading) {
        return(
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign:'center'
            }}>
                <Spin size="large" />
                <span style={{ marginTop: '8px' }}>Loading...</span> {/* Text directly below the spinner */}
            </div>
        )
    }

    const fetchCostOverview = async (userId, dates) => {
        try {
            const start = moment(new Date(String(dates[0]))).format('YYYY-MM-DD')
            const end = moment(new Date(String(dates[1]))).format('YYYY-MM-DD')
            const url = process.env.REACT_APP_BACKEND_URL + "/user/" + userId + "/amazon/data/analytics/expenses?startDate="+ start + "&endDate=" + end;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": localStorage.getItem("accessToken")
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                throw new Error('Unable to get status');
            } else {
                console.log('Response is OK');
                const responseBody = await response.json();
                setCostOverview(responseBody.data)
                setDataLoading(false)
            }
        } catch (error) {
            console.error('Error during get setup status:', error);
        }
    }

    const fetchSuspiciousOrders = async (userId, dates) => {
        try {
            const start = moment(new Date(String(dates[0]))).format('YYYY-MM-DD')
            const end = moment(new Date(String(dates[1]))).format('YYYY-MM-DD')
            const url = process.env.REACT_APP_BACKEND_URL + "/user/" + userId + "/amazon/data/analytics/suspected?startDate="+ start + "&endDate=" + end;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": localStorage.getItem("accessToken")
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                throw new Error('Unable to get status');
            } else {
                console.log('Response is OK');
                const responseBody = await response.json();
                setSuspiciousOrdersInfo(responseBody.data)
                setDataLoading(false)
            }
        } catch (error) {
            console.error('Error during get setup status:', error);
        }
    }

    const dataSets = [
        {
            label: 'Orders',
            backgroundColor: 'rgba(255,255,255,.2)',
            borderColor: 'rgba(255,255,255,.55)',
            data: summaries ? summaries.map(summary => summary.orderCount) : [],
            barPercentage: 0.6,
        },
        {
            label: 'Profit',
            backgroundColor: 'transparent',
            borderColor: 'rgba(255,255,255,.55)',
            pointBackgroundColor: '#39f',
            data: summaries ? summaries.map(summary => Math.round(summary.totalProfit * 100)/100) : [],
        },
        {
            label: 'Revenue',
            backgroundColor: 'rgba(255,255,255,.2)',
            borderColor: 'rgba(255,255,255,.55)',
            data: summaries ? summaries.map(summary => Math.round(summary.totalRevenue * 100)/100) : [],
        },
        {
            label: 'Margin',
            backgroundColor: 'rgba(255,255,255,.2)',
            borderColor: 'rgba(255,255,255,.55)',
            data: summaries ? summaries.map(summary => Math.round(summary.netMargin * 100)/100) : [],
            barPercentage: 0.6,
        },
    ]

    return (
        <>
            {contextHolder}
            <div className="home">
                <Sidebar activePage={"dashboard"} activeUserName={activeUser?.name} activeUserEmail={activeUser?.email} image="/logo.svg" ></Sidebar>
                <div className="home__main">
                    <div className="headinghome">
                        <h1 className="headingtitle">Dashboard</h1>
                        <Stack direction="column" alignItems="flex-start">
                            <DateRangePicker
                                ranges={predefinedRanges}
                                placement="bottomEnd"
                                placeholder="Please select a date range"
                                onOk={handleDateChange}
                                cleanable={false}
                                value={dates[0] ? dates : defaultDateRange}
                                onShortcutClick={handleShortcutDateChange}
                            />
                        </Stack>
                    </div>
                    { dataLoading || !(statInfo && dataSets && costOverview && skuStats)?  
                        <div>
                            <Spin style={{ marginTop: '25%' }}></Spin>
                        </div> 
                    : (statInfo && dataSets && costOverview && skuStats) &&
                        <div>
                            <div className="order-analytics">
                                <OrderAnalytics statInfo={statInfo} dataSets={dataSets} datelist={summaries.map(summary => summary.date)}></OrderAnalytics>
                            </div>
                            <div className="order-analytics">
                                <CostAndMetrics costOverview={costOverview} statInfo={statInfo} skuStats={skuStats} suspiciousOrdersInfo={suspiciousOrdersInfo}/>
                            </div>
                        </div>
                    }
                    {setupDetails && setupDetails?.amazonLinkStatus !== "SUCCESS" &&
                        <div>
                            <NavigateModal
                                title="Finish Your Setup"
                                    body="We need to connect your amazon account with us to collect data, and you don't have to do anything manually."
                                path="/setup"
                            buttonText="Setup"
                        />
                    </div>
                    }
                </div>
            </div>
            {error && errorDetails?.title &&
                <div>
                    <NavigateModal
                        title={errorDetails.title}
                        body={errorDetails.message}
                        path={errorDetails.path}
                        buttonText={errorDetails.buttonText}
                    />
                </div>
            }
        </>
    );
};
