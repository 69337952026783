import React, { useEffect, useState } from 'react';
import { Table, Tag, Spin, notification } from 'antd';

const columns = [
    {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Gross Revenue',
        dataIndex: 'grossRevenue',
        key: 'grossRevenue',
        render: text => `${text.toFixed(2)}`,
    },
    {
        title: 'Gross Profit',
        dataIndex: 'grossProfit',
        key: 'grossProfit',
        render: text => `${text.toFixed(2)}`,
    },
    {
        title: 'COGS',
        dataIndex: 'costOfGoodsSold',
        key: 'costOfGoodsSold',
        render: text => `${text.toFixed(2)}`,
    },
];

export const SearchOrderTable = ({ activeUser, value }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false); 
    const [errorDetails, setErrorDetails] = useState(null); 

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title,
            description: message,
        });
    };

    const fetchSkuDetails = async () => {
        setLoading(true);
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/user/${activeUser.id}/amazon/data/order/skus?orderId=${value}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": localStorage.getItem("accessToken")
                }
            });

            // Check if the response is OK
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("userId");
                    setError(true);
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    });
                }
                throw new Error('Unable to get status');
            } else {
                const responseBody = await response.json();
                const arr = responseBody.data.map(item => ({
                    key: item.id,
                    sku: item.sku,
                    orderId: item.orderId,
                    quantity: item.quantity,
                    grossRevenue: item.grossRevenue,
                    grossProfit: item.grossProfit,
                    costOfGoodsSold: item.costOfGoodsSold
                }));
                setData(arr);
            }
        } catch (error) {
            console.error('Error during fetching SKU details:', error);
            openNotificationWithIcon("error", "Error", "An error occurred while fetching the SKU details.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSkuDetails();
    }, [activeUser, value]);

    return (
        loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', textAlign: 'center' }}>
                <Spin size="large" />
            </div>
        ) : (
            <Table columns={columns} dataSource={data} pagination={false} />
        )
    );
};
