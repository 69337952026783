import React from 'react';
import { Typography } from 'antd';
import './LoginArtwork.css';

const { Title } = Typography;

const LoginArtwork = () => {
  return (
    <div className="left-container">
      <div className="content-wrapper">
        <Title className="login-artwork-title">
          Where amazing things happen
        </Title>
      </div>
      <div className="image-wrapper">
        <img src="loginartwork.svg" alt="Illustration" className="illustration" />
      </div>
    </div>
  );
};

export default LoginArtwork;
