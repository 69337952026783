import { CWidgetStatsE } from "@coreui/react";
import {
    CChartBar,
    CChartLine,
  } from '@coreui/react-chartjs'
import '@coreui/chartjs/dist/css/coreui-chartjs.css'
import { Row, Col} from "antd";
import './OrderAnalytics.css';

// Add this function at the beginning of your component or in a separate utility file
const formatNumberINR = (num) => {
    return num.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
};

export const OrderAnalytics = ({statInfo, dataSets, datelist}) => {
    return (
        <>
            <Row gutter={[12]}>
                <Col span={6}>
                    <CWidgetStatsE
                        className="stats-card"
                        color="primary"
                        value={
                            <h2 className="text-white mb-0">
                                {statInfo.totalOrders}
                            </h2>
                        }
                        title= {
                            <h6 className="fs-14 fw-bold text-white mb-0">
                                {"Total orders"}
                            </h6>
                        } 
                        chart={
                            <CChartBar
                                className=""
                                style={{ height: '70px' }}
                                data={{
                                    labels: datelist,
                                    datasets: [dataSets[0]], // Changed this line
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    },
                                    scales: {
                                    x: {
                                        grid: {
                                        display: false,
                                        drawTicks: false,
                                        },
                                        ticks: {
                                        display: false,
                                        },
                                    },
                                    y: {
                                        border: {
                                        display: false,
                                        },
                                        grid: {
                                        display: false,
                                        drawBorder: false,
                                        drawTicks: false,
                                        },
                                        ticks: {
                                        display: false,
                                        },
                                    },
                                    },
                                }}
                            />
                        }
                    />
                </Col>
                <Col span={6}>
                <CWidgetStatsE
                    className="stats-card"
                    color="info"
                    value={
                        <h2 className="text-white mb-0">
                            {formatNumberINR(statInfo.totalRevenue)}
                        </h2>
                    }
                    title= {
                        <h6 className="fs-14 fw-bold text-white mb-0">
                            {"Total Revenue"}
                        </h6>
                    } 
                    chart={
                        <CChartLine
                        className=""
                        style={{ height: '70px' }}
                        data={{
                            labels: datelist,
                            datasets: [
                                dataSets[2],
                            ],
                        }}
                        options={{
                            plugins: {
                            legend: {
                                display: false,
                            },
                            },
                            maintainAspectRatio: false,
                            scales: {
                            x: {
                                display: false,
                            },
                            y: {
                                display: false,
                            },
                            },
                            elements: {
                            line: {
                                borderWidth: 2,
                                tension: 0.4,
                            },
                            point: {
                                radius: 0,
                                hitRadius: 10,
                                hoverRadius: 4,
                            },
                            },
                        }}
                        />
                    }
                    />
                </Col>
                <Col span={6}>
                <CWidgetStatsE
                    className="stats-card"
                    color="success"
                    value={
                        <h2 className="text-white mb-0 responsive-number">
                            {formatNumberINR(statInfo.totalProfit)}
                        </h2>
                    }
                    title= {
                        <h6 className="fs-14 fw-bold text-white mb-0">
                            {"Total Profits"}
                        </h6>
                    }  
                    chart={
                        <CChartLine
                        className=""
                        style={{ height: '70px' }}
                        data={{
                            labels: datelist,
                            datasets: [
                                dataSets[1],
                            ],
                        }}
                        options={{
                            plugins: {
                            legend: {
                                display: false,
                            },
                            },
                            maintainAspectRatio: false,
                            scales: {
                            x: {
                                display: false,
                            },
                            y: {
                                display: false,
                            },
                            },
                            elements: {
                            line: {
                                borderWidth: 2,
                                tension: 0.4,
                            },
                            point: {
                                radius: 0,
                                hitRadius: 10,
                                hoverRadius: 4,
                            },
                            },
                        }}
                        />
                    }
                    />
                </Col>
                <Col span={6}>
                <CWidgetStatsE
                    className="stats-card"
                    style={{
                        backgroundColor: '#f74f70', // Custom background color
                        color: 'white', // Custom text color
                    }}
                    value={
                        <h2 className="text-white mb-0">
                            {formatNumberINR(statInfo.netMargin)}
                        </h2>
                    }
                    title= {
                        <h6 className="fs-14 fw-bold text-white mb-0">
                            {"Net Margin"}
                        </h6>
                    } 
                    chart={
                        <CChartBar
                        className=""
                        style={{ height: '70px' }}
                        data={{
                            labels: datelist,
                            datasets: [
                                dataSets[3],
                            ],
                        }}
                        options={{
                            maintainAspectRatio: false,
                            plugins: {
                            legend: {
                                display: false,
                            },
                            },
                            scales: {
                            x: {
                                grid: {
                                display: false,
                                drawTicks: false,
                                },
                                ticks: {
                                display: false,
                                },
                            },
                            y: {
                                border: {
                                display: false,
                                },
                                grid: {
                                display: false,
                                drawBorder: false,
                                drawTicks: false,
                                },
                                ticks: {
                                display: false,
                                },
                            },
                            },
                        }}
                        />
                    }
                    />
                </Col>
            </Row>
        </>
    );
}
