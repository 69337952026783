import { DonutChartWrapper } from "../../components/charts/DonutChartWrapper";
import "./CostAndMetrics.css";
import { Row, Col } from "antd";
import { CWidgetStatsE } from "@coreui/react";

export const CostAndMetrics = ({ costOverview, statInfo, skuStats, suspiciousOrdersInfo }) => {
    const labels = Object.keys(costOverview);
    const labelMapping = {
        'COGS': 'Cost of Goods Sold',
        'ProductAdsPaymentEvent-Charge': 'Ads Charges',
        'ServiceFeeEvent-FBAInboundTransportationFee': 'FBA Transport Fees',
        'ServiceFeeEvent-FBAStorageFee': 'FBA Storage Fees',
        'other_expenses': 'Other Expenses'
    };
    
    const formattedLabels = labels.map(label => labelMapping[label] || label);

    const formatNumberINR = (num) => {
        return num ? num.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        }) : '0.00';
    };

    const data = {
        labels: formattedLabels,
        datasets: [
            {
                backgroundColor: [
                    '#ff6384', // Light Salmon
                    '#98D8C8', // Mint
                    '#F7DC6F', // Pale Yellow
                    '#BB8FCE', // Light Purple
                    '#82E0AA', // Light Green
                    '#F8C471', // Light Orange
                    '#85C1E9', // Light Blue
                    '#FF6B6B', // Soft Red
                ],
                data: labels.map(key => costOverview[key] > 0 ? Math.round(costOverview[key] * 100) / 100 : -1 * Math.round(costOverview[key] * 100) / 100),
                cutout: "70%",
                borderRadius: 5,
            },
        ],
    };

    return (
        <div className="cost-and-metrics">
            <div className="cogspiepanel">
                <DonutChartWrapper data={data} title={"Expenses Overview"} />
            </div>
            <div className="metrics-panel">
                <Row gutter={[12]}>
                    <Col span={8}>
                        <CWidgetStatsE
                            className="stats-card"
                            style={{
                                backgroundColor: '#d36d19', // Custom background color
                                color: 'black', // Custom text color
                            }}
                            value={
                                <div className="text-white mb-0">
                                    {skuStats.mostSelling?.map((value) => {
                                        return <p className="mb-0">{value}</p>
                                    })}
                                </div>
                            }
                            title={
                                <h6 className="fs-14 fw-bold text-white mb-0">
                                    {"Most Selling Products"}
                                </h6>
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <CWidgetStatsE
                            className="stats-card"
                            style={{
                                backgroundColor: '#BB8FCE', // Custom background color
                                color: 'black', // Custom text color
                            }}
                            value={
                                <div className="text-white mb-0">
                                    {skuStats.mostProfitable?.map((value) => {
                                        return <p className="mb-0">{value}</p>
                                    })}
                                </div>
                            }
                            title={
                                <h6 className="fs-14 fw-bold text-white mb-0">
                                    {"Most Profitable Products"}
                                </h6>
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <CWidgetStatsE
                            className="stats-card"
                            color="danger"
                            value={
                                <div className="text-white mb-0">
                                    {skuStats.leastProfitable?.map((value) => {
                                        return <p className="mb-0">{value}</p>
                                    })}
                                </div>
                            }
                            title={
                                <h6 className="fs-14 fw-bold text-white mb-0">
                                    {"Least Profitable Products"}
                                </h6>
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={[12]} style={{marginTop: '3%'}}>
                    <Col span={6}>
                        <CWidgetStatsE
                            className="stats-card"
                            color="info"
                            value={
                                <h3 className="text-white mb-0">
                                    {formatNumberINR(statInfo.totalRevenue / statInfo.totalOrders)}
                                </h3>
                            }
                            title={
                                <h6 className="fs-14 fw-bold text-white mb-0">
                                    {"Average Order Value"}
                                </h6>
                            }
                        />
                    </Col>
                    <Col span={6}>
                        <CWidgetStatsE
                            className="stats-card"
                            color="danger"
                            value={
                                <h3 className="text-white mb-0">
                                    {Math.round(skuStats.returnRatio[0] * 10000) / 100}%
                                </h3>
                            }
                            title={
                                <h6 className="fs-14 fw-bold text-white mb-0">
                                    {"Returned Order Ratio"}
                                </h6>
                            }
                        />
                    </Col>
                    <Col span={6}>
                        <CWidgetStatsE
                            className="stats-card"
                            color="warning"
                            value={
                                <h3 className="text-white mb-0">
                                    {suspiciousOrdersInfo?.totalSuspectedOrders}
                                </h3>
                            }
                            title={
                                <h6 className="fs-14 fw-bold text-white mb-0">
                                    {"Suspicious Orders"}
                                </h6>
                            }
                        />
                    </Col>
                    <Col span={6}>
                        <CWidgetStatsE
                            className="stats-card"
                            color="success"
                            value={
                                <h3 className="text-white mb-0">
                                    {formatNumberINR(suspiciousOrdersInfo?.totalPotentialValue)}
                                </h3>
                            }
                            title={
                                <h6 className="fs-14 fw-bold text-white mb-0">
                                    {"Potential Savings"}
                                </h6>
                            }
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}
