import { useState, useContext ,useEffect} from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import './Suspected.css';
import { notification,Spin } from "antd";
import { ApiContext } from '../../ApiContext';
import { NavigateModal } from "../../components/modals/NavigateModal";
import { SuspectedTable } from "./SuspectedTable";
import { defaultDateRange, predefinedRanges } from "../../components/DatePicker";
import { DateRangePicker, Stack } from 'rsuite';
export const Suspected = () => {
    const { activeUser, loading } = useContext(ApiContext);
    const [errorDetails, setErrorDetails] = useState();
    const [error, setError] = useState(false);
    const [dates, setDates] = useState(defaultDateRange);
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, message) => {
        api[type]({
            message: title,
            description: message,
        });
    };

    const handleDateChange = (range) => {
        setDates(range)
        localStorage.setItem("daterange", range);
    };

    const handleShortcutDateChange = (shortcut, event) => {
        setDates(shortcut.value)
        localStorage.setItem("daterange", shortcut.value);
    }

    useEffect(() => {
        const storedRange = localStorage.getItem("daterange");
        if (storedRange) {
            const dateRange = storedRange.split(",").map(dateStr => dateStr ? new Date(dateStr) : null);
            setDates(dateRange);
        }
    }, []);

    if (loading) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center'
            }}>
                <Spin size="large" />
                <span style={{ marginTop: '8px' }}>Loading...</span>
            </div>
        );
    }

    return (
        <>
            <div className="suspected--main">
                <Sidebar activePage={"orders/suspicious"} activeUserName={activeUser?.name} activeUserEmail={activeUser?.email} isOrdersExpanded={true} image="/logo.svg"></Sidebar>
                <div className="orders__main">
                    <div className="heading">
                        <h1 className="headingtitle">Suspicious Orders</h1>
                        <Stack direction="column" alignItems="flex-start">
                            <DateRangePicker
                                ranges={predefinedRanges}
                                placement="bottomEnd"
                                placeholder="Please select a date range"
                                onOk={handleDateChange}
                                cleanable={false}
                                value={dates[0] ? dates : defaultDateRange}
                                onShortcutClick={handleShortcutDateChange}
                            />
                        </Stack>
                    </div>
                    <div className="tablecomponent">
                        <SuspectedTable userId={activeUser?.id} accessToken={localStorage.getItem("accessToken")} dates={dates}></SuspectedTable>
                    </div>
                </div>
            </div>
            {error && errorDetails?.title && (
                <NavigateModal
                    title={errorDetails.title}
                    body={errorDetails.message}
                    path={errorDetails.path}
                    buttonText={errorDetails.buttonText}
                />
            )}
        </>
    );
};
