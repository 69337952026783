import React from 'react';
import './DetailsPanel.css'; // Import the CSS file

// DetailRow Component to handle individual rows with label and value
const DetailRow = ({ label, value, link }) => {
    return (
      <div className="detail-row">
        <div className="label-container">
          <span className="label">{label}</span>
        </div>
        <div className="value-container">
          {link ? (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="value-link"
            >
              {value}
            </a>
          ) : (
            <span className="value">{value}</span>
          )}
        </div>
      </div>
    );
};
  
// InfoPanel Component to handle dynamic rows
export const DetailsPanel = ({ title, details }) => {
    return (
        <div className="details-panel">
            <h5 className="panel-title">{title}</h5>
            <div className="content">
                {details.map((detail, index) => (
                    <DetailRow
                        key={index}
                        label={detail.label}
                        value={detail.value}
                        link={detail.link}
                    />
                ))}
            </div>
        </div>
    );
};