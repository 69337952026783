import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Home } from './pages/home/Home';
import { Login } from './pages/login/Login';
import { Setup } from './pages/setup/Setup';
import { Suspected } from './pages/orders/Suspected';
import { SkuSearch } from './pages/skus/SkuSearch';
import { SearchOrder } from './pages/orders/SearchOrder';
import { AllOrders } from './pages/orders/AllOrders';
import { ApiProvider } from './ApiContext';
import { SkusAll } from './pages/skus/SkusAll';
import { Charges } from './pages/charges/Charges';
import { SkuDetails } from './pages/skus/SkuDetails';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ApiProvider>
          <Routes>
            {/* <Route path="/" element={<LoginPage></LoginPage>}/> */}
            <Route path='/home' element={<Home></Home>} />
            <Route path="/" element={<Login></Login>} />
            <Route path="/setup" element={<Setup></Setup>} />
            <Route path="/charges" element={<Charges></Charges>} />
            <Route path="/orders/suspicious" element={<Suspected></Suspected>} />
            <Route path="/orders/all" element={<AllOrders></AllOrders>} />
            <Route path="/orders/search" element={<SearchOrder />} />
            <Route path="/skus/all" element={<SkusAll></SkusAll>} />
            <Route path="/skus/search" element={<SkuSearch />} />
            <Route path="/skus/details" element={<SkuDetails />} />
          </Routes>
        </ApiProvider>
      </BrowserRouter>
      <SpeedInsights />
    </div>
  );
}

export default App;
