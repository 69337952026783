import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Category, LogoutRounded, ShoppingBag, Tune } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { LineChartOutlined, SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useApi } from '../../ApiContext';
import { ProfileOutlined }from '@ant-design/icons';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

const Sidebar = ({ activePage, activeUserEmail, activeUserName, isOrdersExpanded, isSkuExpanded, image }) => {
  const { setActiveUser, setSetupDetails, setLoading } = useApi();
  const [isOrdersExpandedState, setIsOrdersExpanded] = useState(false);
  const [isSkuExpandedState, setIsSkuExpanded] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    setIsOrdersExpanded(isOrdersExpanded);
  }, [isOrdersExpanded]);

  useEffect(() => {
    setIsSkuExpanded(isSkuExpanded);
  }, [isSkuExpanded]);

  const handleLogout = () => {
    localStorage.removeItem("accessToken")
    localStorage.removeItem("userId")
    navigate("/")
    window.location.reload();
  }
  const toggleOrders = () => {
    setIsOrdersExpanded(prev => !prev);
  };

  const toggleSku = () => {
    setIsSkuExpanded(prev => !prev);
  };

  return (
    <div className="sidebar">
      <div>
        <div className="sidebar__logo">
          <img src={image} alt="Logo" />
        </div>
        <div className="sidebar__section">
          <div className={`sidebar__item ${activePage === 'dashboard' ? 'sidebar__item--active' : ''}`} onClick={() => { navigate("/home") }}>
            <DashboardIcon />
            <span>Dashboard</span>
          </div>
          <div className={`sidebar__item ${activePage === 'orders' ? 'sidebar__item--active' : ''}`} onClick={toggleOrders}>
            <ShoppingBag />
            <span style={{ paddingRight: '10px' }}>Orders</span>
            {isOrdersExpandedState ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
          {isOrdersExpandedState && (
            <div className="sidebar__subitems">
              {/* Tab1 Item */}
              <div
                className={`sidebar__subitem ${activePage === 'orders/all' ? 'sidebar__subitem--active' : ''}`}
                onClick={() => {
                  navigate("/orders/all");
                  setIsOrdersExpanded(false);
                }}
              >
                <LineChartOutlined />
                <span style={{ paddingLeft: '10px' }}>Order Analysis</span>
              </div>
              {/* Tab2 Item */}
              <div
                className={`sidebar__subitem ${activePage === 'orders/search' ? 'sidebar__subitem--active' : ''}`}
                onClick={() => {
                  navigate("/orders/search");
                  setIsOrdersExpanded(false);
                }}
              >
                <SearchOutlined />
                <span style={{ paddingLeft: '10px' }}>Search Order</span>
              </div>
              <div className={`sidebar__subitem ${activePage === 'orders/suspicious' ? 'sidebar__subitem--active' : ''}`}
                onClick={() => {
                  navigate("/orders/suspicious");
                  setIsOrdersExpanded(false);
                }}>
                <ShoppingCartOutlined />
                <span style={{ paddingLeft: '10px' }}>Suspicious Orders</span>
              </div>
            </div>
          )}
          <div className={`sidebar__item ${activePage === 'skus' ? 'sidebar__item--active' : ''}`} onClick={toggleSku}>
            <Category />
            <span style={{ paddingRight: '17px' }}>SKUs</span>
            {isSkuExpandedState ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
          {isSkuExpandedState && (
            <div className="sidebar__subitems">
              {/* Tab1 Item */}
              <div
                className={`sidebar__subitem ${activePage === 'skus/all' ? 'sidebar__subitem--active' : ''}`}
                onClick={() => {
                  navigate("/skus/all");
                  setIsSkuExpanded(false);
                }}
              >
                <LineChartOutlined />
                <span style={{ paddingLeft: '10px' }}>SKU Analysis</span>
              </div>
              {/* Tab2 Item */}
              <div
                className={`sidebar__subitem ${activePage === 'skus/search' ? 'sidebar__subitem--active' : ''}`}
                onClick={() => {
                  navigate("/skus/search");
                  setIsSkuExpanded(false);
                }}
              >
                <SearchOutlined />
                <span style={{ paddingLeft: '10px' }}>Search SKUs</span>
              </div>
              <div
                className={`sidebar__subitem ${activePage === 'skus/details' ? 'sidebar__subitem--active' : ''}`}
                onClick={() => {
                  navigate("/skus/details");
                  setIsSkuExpanded(false);
                }}
              >
                <ProfileOutlined />
                <span style={{ paddingLeft: '10px' }}>SKUs Details</span>
              </div>
            </div>
          )}
          <div className={`sidebar__item ${activePage === 'charges' ? 'sidebar__item--active' : ''}`} onClick={() => { navigate("/charges") }}>
            <LocalAtmIcon />
            <span>Amazon Charges</span>
          </div>
          <div className={`sidebar__item ${activePage === 'setup' ? 'sidebar__item--active' : ''}`} onClick={() => { navigate("/setup") }}>
            <Tune />
            <span>Setup</span>
          </div>

        </div>

        {/* <div className="sidebar__section">
          <h4>Personal</h4>
          <div className={`sidebar__item ${activePage === 'notifications' ? 'sidebar__item--active' : ''}`} onClick={() => { navigate("/notifications") }}>
            <NotificationsIcon />
            <span>Notifications <span className="notification-dot">N</span> </span>
          </div>
          <div className={`sidebar__item ${activePage === 'settings' ? 'sidebar__item--active' : ''}`} onClick={() => { navigate("/settings") }}>
            <ManageAccounts />
            <span>Settings</span>
          </div>
        </div> */}
      </div>
      <div>
        <div className="sidebar__logout">
          <div className="sidebar__item" onClick={handleLogout}>
            <LogoutRounded />
            <span>Logout</span>
          </div>
        </div>
        <div className="sidebar__profile">
          <img src="https://img.icons8.com/?size=100&id=108652&format=png&color=000000" alt="User" />
          <div>
            <strong>{activeUserName ?? "Amazon Seller"}</strong>
            {activeUserEmail ? <span>{activeUserEmail}</span> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
