import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton } from "@coreui/react";

export const NavigateModal = ({title, body, path, buttonText}) => {
    return (
        <div>
            <CModal
                alignment="center"
                backdrop="static"
                scrollable
                visible={true}
            >
                <CModalHeader closeButton={false}>
                    <CModalTitle>{title}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p>
                        {body}
                    </p>
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" href={path}>{buttonText}</CButton>
                </CModalFooter>
            </CModal>
        </div>
    );
}