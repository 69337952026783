import React, { useEffect, useState } from 'react';
import { Collapse, Spin, notification } from 'antd';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableDataCell, CTableBody } from "@coreui/react";
import './FinancialDetails.css';

const { Panel } = Collapse;

export const FinancialDetails = ({ activeUser, value }) => {
    const [statInfo, setStatInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorDetails, setErrorDetails] = useState({});

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title,
            description: message,
        });
    };

    const fetchFinancialDetails = async () => {
        setLoading(true);
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/user/${activeUser.id}/amazon/data/order/events?orderId=${value}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": localStorage.getItem("accessToken"),
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("userId");
                    setError(true);
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    });
                } else if (response.status === 400) {
                    openNotificationWithIcon("error", "Order not found", `${value} not present with us, If this is a valid SKU, please update it using cost sheet at Setup`);
                }
                throw new Error('Unable to get status');
            }

            const responseBody = await response.json();
            const dataArray = Object.keys(responseBody.data).map(eventKey => {
                const eventDetails = responseBody.data[eventKey];
                return {
                    eventType: eventKey.split('---')[0].replace('Event', ''),
                    eventDate: new Date(eventKey.split('---')[1]).toLocaleDateString(),
                    ...eventDetails,
                };
            });

            setStatInfo(dataArray);
        } catch (error) {
            console.error('Error during fetching financial details:', error);
            openNotificationWithIcon("error", "Error", "An error occurred while fetching the financial details.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFinancialDetails();
    }, [activeUser, value]);

    if (loading) {
        return <Spin size="large" />;
    }

    if (error) {
        return <p>Error loading data. Please try again.</p>;
    }

    return (
        <Collapse accordion>
            {statInfo.map((event, index) => (
                <Panel header={`${event.eventDate} - ${event.eventType} - ${event.TotalAmount?.toFixed(2)}`} key={index}>
                    <div className="details-container">
                        <h4 style={{ textAlign: 'center' }}>Details</h4>
                        <CTable>
                            <CTableHead>
                                <CTableRow>
                                    <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {Object.keys(event).map((detailKey, idx) => (
                                    detailKey !== 'eventType' && detailKey !== 'eventDate' && detailKey !== 'TotalAmount' && (
                                        <CTableRow key={idx}>
                                            <CTableDataCell>{detailKey}</CTableDataCell>
                                            <CTableDataCell>{typeof event[detailKey] === 'number' ? `${event[detailKey].toFixed(2)}` : event[detailKey]}</CTableDataCell>
                                        </CTableRow>
                                    )
                                ))}
                                <CTableRow>
                                    <CTableDataCell><strong>Total</strong></CTableDataCell>
                                    <CTableDataCell><strong>{event.TotalAmount?.toFixed(2)}</strong></CTableDataCell>
                                </CTableRow>
                            </CTableBody>
                        </CTable>

                    </div>
                </Panel>
            ))}
        </Collapse>
    );
};
