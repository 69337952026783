import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { NavigateModal } from "../../components/modals/NavigateModal";
import { DataGrid, gridClasses, useGridApiContext, useGridSelector, GridPagination, gridPageCountSelector } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import moment from 'moment';

function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <MuiPagination
            color="primary"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}
        />
    );
}

function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const columns = [
    {
        field: "amazonEventDate",
        flex: 1,
        sortable: false,
        headerName: "Event Date",
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        renderHeader: (params) => (
            <strong>
                {'Event Date'}
            </strong>
        ),
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Event Type'}
            </strong>
        ),
        field: "eventType",
        headerName: 'Event Type',
        flex: 1,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Event Name'}
            </strong>
        ),
        field: "eventName",
        headerName: 'Event Name',
        flex: 1,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Amount'}
            </strong>
        ),
        field: "amount",
        headerName: 'Amount',
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Correlation Id'}
            </strong>
        ),
        field: "relatedId",
        headerName: 'Correlation Id',
        flex: 1,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
    }
    
];

const getQueryParams = (params) => ({
    startDate: params.dates?.start,
    endDate: params.dates?.end
});


export const ChargesTable = ({ userId, accessToken, dates }) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [errorDetails, setErrorDetails] = useState()
    const [error, setError] = useState(false)
    const [totalRows, setTotalRows] = useState(100)
    const [tableParams, setTableParams] = useState({
        dates: {
            start: "",
            end: ""
        }
    });

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (dates?.[0] && dates?.[1] && accessToken && userId) {
                const formattedDates = {
                    start: moment(new Date(String(dates[0]))).format('YYYY-MM-DD'),
                    end: moment(new Date(String(dates[1]))).format('YYYY-MM-DD'),
                };
                
                setTableParams(prev => ({
                    ...prev,
                    dates: formattedDates
                }));
                
                const queryParams = {
                    startDate: formattedDates.start,
                    endDate: formattedDates.end
                };
                
                const fetchData = async () => {
                    setLoading(true);
                    try {
                        const url = process.env.REACT_APP_BACKEND_URL + `/user/` + userId + `/amazon/data/charges?${qs.stringify(queryParams)}`;
                        const response = await fetch(url, {
                            method: "GET",
                            headers: {
                                "accessToken": accessToken
                            }
                        });

                        if (!response.ok) {
                            if (response.status === 401) {
                                localStorage.removeItem("accessToken")
                                localStorage.removeItem("userId")
                                setError(true)
                                setErrorDetails({
                                    title: "Session Expired",
                                    message: "Please log in again, Click on logout to navigate to login screen",
                                    buttonText: "Logout",
                                    path: "/"
                                })
                            }
                            throw new Error('Unable to get status');
                        } else {
                            console.log('Response is OK');
                            const responseBody = await response.json();
                            setData(responseBody.data);
                            setLoading(false);
                            setTotalRows(responseBody.count)
                        }
                    } catch (error) {
                        console.error('Error during get setup status:', error);
                        setLoading(false);
                    }
                };

                fetchData();
            }
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [dates, accessToken, userId]);

    return (
        <div>
            <div style={{ height: `calc(100vh - 150px)`, width: '100%' }}>
                <DataGrid
                    rowHeight={45}
                    rows={data}
                    slots={{
                        pagination: CustomPagination,
                    }}
                    columns={columns}
                    loading={loading}
                    pageSizeOptions={[10, 20, 50, 100]}
                    disableColumnSelector
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'amazonEventDate', sort: 'asc' }],
                        },
                        pagination: {
                            paginationModel: { pageSize: 20, page: 0 },
                        },
                    }}
                    sx={{
                        [`& .${gridClasses.menuIcon}`]: {
                            visibility: "visible",
                            width: "auto"
                        }
                    }}
                />
            </div>

            {error && errorDetails?.title &&
                <div>
                    <NavigateModal
                        title={errorDetails.title}
                        body={errorDetails.message}
                        path={errorDetails.path}
                        buttonText={errorDetails.buttonText}
                    />
                </div>
            }
        </div>
    );
}
