import React from 'react';
import { CChartDoughnut } from '@coreui/react-chartjs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, plugins } from 'chart.js';
import './ChartWrappers.css';

// Register necessary chart components
ChartJS.register(ArcElement, Tooltip, Legend);

// Define a custom plugin to render text inside the donut
const centerTextPlugin = {
  id: 'centerText',
  afterDraw(chart) {
    const { ctx, width, height } = chart;
    const total = chart.data.datasets[0].data.reduce((a, b) => a + b, 0); // Calculate the total value

    ctx.save();
    ctx.font = 'bold 24px Helvetica';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#333';

    const text = `${Math.round(total * 100) / 100}`;
    ctx.fillText(text, width / 2, height / 2);
    ctx.restore();
  },
};

export const DonutChartWrapper = ({data, title}) => {

  const options = {
    responsive: true,
    cutout: '70%', // This makes it look like a donut
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
  };

  return (
    <div>
        <h5 className="charttitle">{title}</h5>
        <CChartDoughnut data={data} options={options} plugins={[centerTextPlugin]} className="piechart"/>
    </div>
  );
};