import { useEffect, useState, useContext } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import "./SKUs.css"
import '../orders/Orders.css';
import { useNavigate } from "react-router-dom";
import { Empty, notification, Spin } from "antd";
import { NavigateModal } from "../../components/modals/NavigateModal";
import { ApiContext } from '../../ApiContext';
import { defaultDateRange, predefinedRanges } from "../../components/DatePicker";
import { DateRangePicker, Stack } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import Search from "antd/es/input/Search";
import { DetailsPanel } from "../../components/panel/DetailsPanel";
import { Card, Col, Row, Statistic } from 'antd';
import moment from "moment";
import { LineChartWrapper } from "../../components/charts/LineChartWrapper";
import { BarChartWrapper } from "../../components/charts/BarChartWrapper";

export const SkuSearch = () => {
    const { activeUser, setupDetails, loading } = useContext(ApiContext);
    const navigate = useNavigate()
    const [errorDetails, setErrorDetails] = useState()
    const [error, setError] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, message) => {
        api[type]({
            message: title,
            description: message,
        });
    };
    const [dates, setDates] = useState(defaultDateRange);
    const [searchValue, setSearchValue] = useState('')
    const [dataset, setDataset] = useState([])
    const [statInfo, setStatInfo] = useState()
    const [details, setDetails] = useState()
    const [dataLoading, setDataLoading] = useState(false)

    const handleDateChange = (range) => {
        setDates(range)
        localStorage.setItem("daterange", range);
    };

    const handleShortcutDateChange = (shortcut, event) => {
        setDates(shortcut.value)
        localStorage.setItem("daterange", shortcut.value);
    }

    useEffect(() => {
        const storedRange = localStorage.getItem("daterange");
        if (storedRange) {
            const dateRange = storedRange.split(",").map(dateStr => dateStr ? new Date(dateStr) : null);
            setDates(dateRange);
        }
    }, []);

    useEffect(() => {
        if (activeUser && dates[0] && searchValue) {
            setDataLoading(true)
            fetchDataSet(activeUser, dates);
        }
    }, [dates, searchValue])

    const fetchDataSet = async (activeUser, dates) => {
        try {
            const start = moment(new Date(String(dates[0]))).format('YYYY-MM-DD')
            const end = moment(new Date(String(dates[1]))).format('YYYY-MM-DD')
            const url = process.env.REACT_APP_BACKEND_URL + "/user/" + activeUser.id + "/amazon/data/sku?sku=" 
            + searchValue + "&startDate="+ start + "&endDate=" + end;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": localStorage.getItem("accessToken")
                }
            });
            // Check if the response is OK
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                throw new Error('Unable to get status');
            } else {
                console.log('Response is OK');
                const responseBody = await response.json();
                setDataset(responseBody.data.dateWiseStats)
                setStatInfo(responseBody.data.info)
                setDataLoading(false)
            }
        } catch (error) {
            console.error('Error during get setup status:', error);
        }
    }

    if (loading) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center'
            }}>
                <Spin size="large" />
                <span style={{ marginTop: '8px' }}>Loading...</span> {/* Text directly below the spinner */}
            </div>
        )
    }

    const onSearch = (value, e, source) => {
        // TODO : Handle search, call BE for Data
        setSearchValue(value)
        if(source.source === "clear"){
            return
        }
        if (activeUser) {
            fetchSkuDetails(activeUser, value);
        } else {
            localStorage.removeItem("accessToken")
            localStorage.removeItem("userId")
            setError(true)
            setErrorDetails({
                title: "Session Expired",
                message: "Please log in again, Click on logout to navigate to login screen",
                buttonText: "Logout",
                path: "/"
            })
        }
    }

    const fetchSkuDetails = async (activeUser, value) => {
        try {
            const url = process.env.REACT_APP_BACKEND_URL + "/user/" + activeUser.id + "/skus?sku=" 
            + value;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": localStorage.getItem("accessToken")
                }
            });

            // Check if the response is OK
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                if (response.status === 400) {
                    openNotificationWithIcon("error", "SKU not found", `${value} not present with us, If this is a valid sku, please update it using cost sheet at Setup` )
                }
                throw new Error('Unable to get status');
            } else {
                console.log('Response is OK');
                const responseBody = await response.json();
                const arr = []
                arr.push({label: "Sku", value : responseBody.data.sku}, {label: "Cost", value : Math.round(responseBody.data.cost * 100) / 100})
                setDetails(arr)
            }
        } catch (error) {
            console.error('Error during get setup status:', error);
        }
    }

    const series = [
        {
            dataKey: 'totalGrossProfit',
            label: "Gross Profit"
        },
        {
            dataKey: 'totalGrossRevenue',
            label: "Gross Revenue"
        },
    ]

    const orderSeries = [
        {
            dataKey: 'totalOrders',
            label: "Orders",
            color: "orange",
        },
        {
            dataKey: 'orderedQty',
            label: "Ordered Quantity",
            color: "green",
        },
        {
            dataKey: 'returnedQty',
            label: "Returned Quantity",
            color: "red",
        },
    ]

    return (
        <>
            {contextHolder}
            <div className="sku-main">
                <Sidebar activePage={"skus/search"} activeUserEmail={activeUser?.email} activeUserName={activeUser?.name} isSkuExpanded={true} image="/logo.svg"></Sidebar>
                <div className="orders__main">
                    <div className="heading">
                        <h1 className="headingtitle">Search SKU</h1>
                        <Stack direction="column" alignItems="flex-start">
                            <DateRangePicker
                                ranges={predefinedRanges}
                                showOneCalendar
                                placement="auto"
                                placeholder="Please select a date range"
                                onOk={handleDateChange}
                                cleanable={false}
                                value={dates[0] ? dates : defaultDateRange}
                                onShortcutClick={handleShortcutDateChange}
                            />
                        </Stack>
                    </div>
                    <div className="skudetails">
                        <div className="searchbar">
                            <Search
                                placeholder="Enter SKU here"
                                allowClear
                                enterButton="Search"
                                size="large"
                                onSearch={onSearch}
                            />
                        </div>
                        { dataLoading ?  
                            <div>
                                <Spin style={{ marginTop: '25%' }}></Spin>
                            </div> 
                        : (searchValue && statInfo && details) ?
                            <div>
                                <div className="panel_and_stats">
                                    <div className="panel">
                                        <DetailsPanel title="Basic Details" details={details}></DetailsPanel>
                                    </div>
                                    <div className="stats">
                                        <Row gutter={[16, 16]}>
                                            <Col span={12}>
                                                <Card bordered={false} className="statComp" title={<h5 className="cardheading">Total Orders</h5>}>
                                                    <Statistic
                                                        value={statInfo.totalOrders}
                                                        valueStyle={{ color: statInfo.totalOrders > 0 ? '#3f8600' : "#cf1322", fontSize: "clamp(14px, 1.5vw, 24px)" }}
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card bordered={false} className="statComp" title={<h5 className="cardheading">Total Gross Revenue</h5>}>
                                                    <Statistic
                                                        value={Math.round(statInfo.totalRevenue * 100) / 100}
                                                        valueStyle={{ color: statInfo.totalRevenue > 0 ? '#3f8600' : "#cf1322", fontSize: "clamp(14px, 1.5vw, 24px)" }}
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card bordered={false} className="statComp" title={<h5 className="cardheading">Total Gross Profit</h5>}>
                                                    <Statistic
                                                        value={Math.round(statInfo.totalProfit * 100) / 100}
                                                        valueStyle={{ color: statInfo.totalProfit > 0 ? '#3f8600' : "#cf1322", fontSize: "clamp(14px, 1.5vw, 24px)" }}
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card bordered={false} className="statComp" title={<h5 className="cardheading">Average Gross Margin</h5>}>
                                                    <Statistic
                                                        value={Math.round((statInfo.totalProfit * 100 / statInfo.totalCost)*100)/100 }
                                                        valueStyle={{ color: statInfo.totalOrders > 0 ? '#3f8600' : "#cf1322", fontSize: "clamp(14px, 1.5vw, 24px)" }}
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="skuchart">
                                    <BarChartWrapper dataset={dataset} series={series} title={"Revenue and Profit Chart"}/>
                                </div>
                                <div className="skuchart">
                                    <LineChartWrapper dataset={dataset} series={orderSeries} title={"Orders Chart"}/>
                                </div>
                            </div>
                        :   <div style={{marginTop: "25%"}}>
                                <Empty/>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {error && errorDetails?.title &&
                <div>
                    <NavigateModal
                        title={errorDetails.title}
                        body={errorDetails.message}
                        path={errorDetails.path}
                        buttonText={errorDetails.buttonText}
                    />
                </div>
            }
        </>
    );
}