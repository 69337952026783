import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import { Empty, notification, Spin } from "antd";
import { NavigateModal } from "../../components/modals/NavigateModal";
import './Orders.css';
import { ApiContext } from '../../ApiContext';
import Search from "antd/es/input/Search";
import { DetailsPanel } from "../../components/panel/DetailsPanel";
import { ArrowDownOutlined, ArrowUpOutlined, LikeOutlined, MoneyCollectTwoTone } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';
import { SearchOrderTable } from "./SearchOrderTable";
import { FinancialDetails } from "./FinancialDetails";

export const SearchOrder = () => {
    const { activeUser, setupDetails, loading } = useContext(ApiContext);
    const navigate = useNavigate()
    const [errorDetails, setErrorDetails] = useState()
    const [error, setError] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, message) => {
        api[type]({
            message: title,
            description: message,
        });
    };

    const [dataset, setDataset] = useState([])
    const [statInfo, setStatInfo] = useState()
    const [details, setDetails] = useState()
    const [dataLoading, setDataLoading] = useState(false);


    useEffect(() => {
        if (activeUser && searchValue) {
            setDataLoading(true)
            fetchDataSet(activeUser);
        }
    }, [searchValue])

    const fetchDataSet = async (activeUser) => {
        try {
            const url = process.env.REACT_APP_BACKEND_URL + "/user/" + activeUser.id + "/amazon/data/order/stats?orderId="
                + searchValue;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": localStorage.getItem("accessToken")
                }
            });

            // Check if the response is OK
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                throw new Error('Unable to get status');
            } else {
                console.log('Response is OK');
                const responseBody = await response.json();
                setStatInfo(responseBody.data)
                setDataLoading(false)
            }
        } catch (error) {
            console.error('Error during get setup status:', error);
        }
    }


    const onSearch = (value, e, source) => {
        // No need for API calls, manually changing search value
        setSearchValue(value);

        if (source.source === "clear") return;

        // Set static data when searched
        if (activeUser) {
            fetchOrderDetails(activeUser, value);
        } else {
            localStorage.removeItem("accessToken")
            localStorage.removeItem("userId")
            setError(true)
            setErrorDetails({
                title: "Session Expired",
                message: "Please log in again, Click on logout to navigate to login screen",
                buttonText: "Logout",
                path: "/"
            })
        }
    }

    const fetchOrderDetails = async (activeUser, value) => {
        try {
            const url = process.env.REACT_APP_BACKEND_URL + "/user/" + activeUser.id + "/amazon/data/order?orderId="
                + value;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": localStorage.getItem("accessToken")
                }
            });

            // Check if the response is OK
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                if (response.status === 400) {
                    openNotificationWithIcon("error", "Order not found", `${value} not present with us, If this is a valid order, please update it using cost sheet at Setup`)
                    setDataLoading(false)
                }
                throw new Error('Unable to get status');
            } else {
                console.log('Response is OK');
                const responseBody = await response.json();
                const arr = []
                arr.push({ label: "Order", value: responseBody.data.amazonOrderId },
                    { label: "Total Amount", value: `${responseBody.data.orderTotal?.CurrencyCode} ${responseBody.data.orderTotal?.Amount}` },
                    { label: "Status", value: responseBody.data.amazonOrderStatus },
                    { label: "Address", value: `${responseBody.data.shippingAddress?.City},${responseBody.data.shippingAddress?.StateOrRegion},${responseBody.data.shippingAddress?.PostalCode},${responseBody.data.shippingAddress?.CountryCode}` },
                    { label: "Marketplace", value: responseBody.data.marketplaceId }
                )
                setDetails(arr)
            }
        } catch (error) {
            console.error('Error during get setup status:', error);
        }
    }
    if (loading) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center'
            }}>
                <Spin size="large" />
                <span style={{ marginTop: '8px' }}>Loading...</span> {/* Text directly below the spinner */}
            </div>
        )
    }

    return (
        <>
            {contextHolder}
            <div className="orders">
                <Sidebar activePage={"orders/search"} activeUserName={activeUser?.name} activeUserEmail={activeUser?.email} isOrdersExpanded={true} image="/logo.svg"></Sidebar>
                <div className="orders__main">
                    <div className="heading">
                        <h1 className="headingtitle">Search Orders</h1>
                    </div>
                    <div className="orderdetails">
                        <div className="searchbar">
                            <Search
                                placeholder="Enter Order Id here"
                                allowClear
                                enterButton="Search"
                                size="large"
                                onSearch={onSearch}
                            />
                        </div>
                        {dataLoading ?
                            <div>
                                <Spin style={{ marginTop: '25%' }}></Spin>
                            </div>
                            : (searchValue && statInfo && details) ?
                                <div>
                                    <div className="panel_and_stats">
                                        <div className="panel">
                                            <DetailsPanel title="Basic Details" details={details}></DetailsPanel>
                                        </div>
                                        <div className="stats">
                                            <Row gutter={[16, 16]}>
                                                <Col span={8}>
                                                    <Card bordered={false} className="statComp" title={<h5 className="cardheading">Gross Revenue</h5>}>
                                                        <Statistic
                                                            value={Math.round(statInfo.grossRevenue * 100) / 100}
                                                            valueStyle={{ color: statInfo.grossRevenue > 0 ? '#3f8600' : "#cf1322", fontSize: "clamp(14px, 1.5vw, 24px)" }}
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card bordered={false} className="statComp" title={<h5 className="cardheading">COGS</h5>}>
                                                        <Statistic
                                                            value={Math.round(statInfo.costOfGoodsSold * 100) / 100}
                                                            valueStyle={{ color: statInfo.costOfGoodsSold > 0 ? '#3f8600' : "#cf1322", fontSize: "clamp(14px, 1.5vw, 24px)" }}
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card bordered={false} className="statComp" title={<h5 className="cardheading">Gross Profit</h5>}>
                                                        <Statistic
                                                            value={Math.round(statInfo.grossProfit * 100) / 100}
                                                            valueStyle={{ color: statInfo.grossProfit > 0 ? '#3f8600' : "#cf1322", fontSize: "clamp(14px, 1.5vw, 24px)" }}
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card bordered={false} className="statComp" title={<h5 className="cardheading">Net Profit</h5>}>
                                                        <Statistic
                                                            value={Math.round(statInfo.netProfit * 100) / 100}
                                                            valueStyle={{ color: statInfo.netProfit > 0 ? '#3f8600' : "#cf1322", fontSize: "clamp(14px, 1.5vw, 24px)" }}
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card bordered={false} className="statComp" title={<h5 className="cardheading">Gross Margin</h5>}>
                                                        <Statistic
                                                            value={Math.round(statInfo.grossMargin * 100) / 100}
                                                            valueStyle={{ color: statInfo.netMargin > 0 ? '#3f8600' : "#cf1322", fontSize: "clamp(14px, 1.5vw, 24px)" }}
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card bordered={false} className="statComp" title={<h5 className="cardheading">Net Margin</h5>}>
                                                        <Statistic
                                                            value={Math.round(statInfo.netMargin * 100) / 100}
                                                            valueStyle={{ color: statInfo.netMargin > 0 ? '#3f8600' : "#cf1322", fontSize: "clamp(14px, 1.5vw, 24px)" }}
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="secondpanel">
                                        <div className="secondstats">
                                            <SearchOrderTable activeUser={activeUser} value={searchValue}></SearchOrderTable>
                                        </div>
                                        <div className="financial-details-box">
                                            <h5 className="title">Financial Details</h5>
                                            <FinancialDetails activeUser={activeUser} value={searchValue} />
                                        </div>
                                    </div>
                                </div>
                                : <div style={{ marginTop: "25%" }}>
                                    <Empty />
                                </div>
                        }
                    </div>
                </div>
            </div>
            {error && errorDetails?.title &&
                <div>
                    <NavigateModal
                        title={errorDetails.title}
                        body={errorDetails.message}
                        path={errorDetails.path}
                        buttonText={errorDetails.buttonText}
                    />
                </div>
            }
        </>

    );

}