import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginArtwork from "../../components/loginartwork/LoginArtwork";
import './Login.css';
import { Form, Input, Button, Typography, Space } from 'antd';
import { useApi } from '../../ApiContext';
import { MailOutlined, LockOutlined, EyeOutlined, EyeInvisibleOutlined, UserOutlined, BuildOutlined, ShopOutlined } from '@ant-design/icons';

const { Title } = Typography;

export const Login = () => {
    const navigate = useNavigate();
    const { setActiveUser, setSetupDetails, setLoading } = useApi();
    const [form] = Form.useForm();
    const [error, setError] = useState('');
    const [isSignup, setIsSignup] = useState(false);

    useEffect(() => {
        if("accessToken" in localStorage){
            navigate("/home")
        }
    }, [navigate]);

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    const handleSubmit = async (values) => {
        const { email, password, businessName } = values;
        
        if (!validateEmail(email)) {
            setError('Please enter a valid email');
            return;
        }

        setError('');

        try {
            const url = process.env.REACT_APP_BACKEND_URL + (isSignup ? "/signup" : "/login");
            const body = isSignup ? { email, password, businessName } : { email, password };

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
    
            if (!response.ok) {
                const responseBody = await response.json();
                setError(responseBody.data || `Unable to ${isSignup ? 'signup' : 'login'}`);
                return;
            }
    
            const responseBody = await response.json();
            localStorage.setItem("accessToken", responseBody.data);
            setActiveUser(null);
            setSetupDetails(null);
            setLoading(true);
            
            navigate("/home");
            window.location.reload();
        } catch (error) {
            console.error(`Error during ${isSignup ? 'signup' : 'login'}:`, error);
        }
    };

    return (
        <div className="loginpage">
            <LoginArtwork />
            <div className="loginpanel">
                <div className="logowrapper">
                    <Space direction="vertical" align="center" size="small">
                        <img src="logo.svg" alt="logo" style={{ width: 'auto', height: '120px' }} />
                        <Title level={2} style={{ marginBottom: 0 }}>
                            {isSignup ? 'Sign Up' : 'Login'}
                        </Title>
                        <Typography.Text type="secondary">
                            {isSignup ? 'Create your account' : 'Welcome back'}
                        </Typography.Text>
                    </Space>
                </div>
                <div className="login-container">
                    <Form form={form} onFinish={handleSubmit} layout="vertical" size="large">
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input 
                                prefix={<MailOutlined className="site-form-item-icon" />} 
                                placeholder="Email" 
                                className="login-input"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="Password"
                                iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                        {isSignup && (
                            <Form.Item
                                name="businessName"
                                rules={[{ required: true, message: 'Please input your business name!' }]}
                            >
                                <Input
                                    prefix={<ShopOutlined className="site-form-item-icon" />}
                                    placeholder="Business Name"
                                />
                            </Form.Item>
                        )}
                        {error && <div className="error-message">{error}</div>}
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                {isSignup ? 'Sign Up' : 'Login'}
                            </Button>
                        </Form.Item>
                    </Form>
                    <Button 
                        type="link" 
                        onClick={() => {
                            setIsSignup(!isSignup);
                            form.resetFields();
                        }} 
                        className="toggle-signup"
                    >
                        {isSignup ? 'Already have an account? Log in' : 'Don\'t have an account? Sign up'}
                    </Button>
                </div>
            </div>
        </div>
    );
};
