import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { NavigateModal } from "../../components/modals/NavigateModal";
import { DataGrid, gridClasses, useGridApiContext, useGridSelector, GridPagination, gridPageCountSelector } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import moment from 'moment';

function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <MuiPagination
            color="primary"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}
        />
    );
}

function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const columns = [
    {
        field: "sku",
        flex: 1,
        sortable: false,
        headerName: "SKU",
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        renderHeader: (params) => (
            <strong>
                {'SKU'}
            </strong>
        ),
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Total Orders'}
            </strong>
        ),
        field: "totalOrders",
        headerName: 'Total Orders',
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Total Quantity'}
            </strong>
        ),
        field: "totalOrderedQuantity",
        headerName: 'Total Quantity',
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Returns'}
            </strong>
        ),
        field: "totalReturnQuantity",
        headerName: 'Returns',
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        valueGetter: (value, row) => {
            return row.totalOrderedQuantity > 0 ? Math.round((value/row.totalOrderedQuantity) * 10000)/100 : 0;
        },
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Cost'}
            </strong>
        ),
        field: "cost",
        headerName: 'Cost',
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Revenue'}
            </strong>
        ),
        field: "totalRevenue",
        headerName: 'Revenue',
        valueFormatter: (value) => {
            return `${Math.round(value * 100) / 100}`;
        },
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Total Cost'}
            </strong>
        ),
        field: "totalCost",
        headerName: 'Total Cost',
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Gross Profit'}
            </strong>
        ),
        field: "totalProfit",
        headerName: 'Gross Profit',
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
    }
];

const getQueryParams = (params) => ({
    startDate: params.dates?.start,
    endDate: params.dates?.end
});


export const SKUTable = ({ userId, accessToken, dates }) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [errorDetails, setErrorDetails] = useState()
    const [error, setError] = useState(false)
    const [totalRows, setTotalRows] = useState(100)
    const [tableParams, setTableParams] = useState({
        dates: {
            start: moment(new Date(String(dates[0]))).format('YYYY-MM-DD'),
            end: moment(new Date(String(dates[1]))).format('YYYY-MM-DD'),
        }
    });

    useEffect(() => {
        if (dates && dates[0] && dates[1]) {
            setTableParams(prev => ({
                ...prev,
                dates: {
                    start: moment(new Date(String(dates[0]))).format('YYYY-MM-DD'),
                    end: moment(new Date(String(dates[1]))).format('YYYY-MM-DD'),
                }
            }));
        }
    }, [dates]);
    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (accessToken && userId && tableParams.dates.start) {
                fetchData(accessToken, userId);
            }
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [tableParams]);

    const fetchData = async (accessToken, userId) => {
        setLoading(true);
        try {
            const url = process.env.REACT_APP_BACKEND_URL + `/user/` + userId + `/amazon/data/skus?${qs.stringify(getQueryParams(tableParams))}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": accessToken
                }
            });

            // Check if the response is OK
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                throw new Error('Unable to get status');
            } else {
                console.log('Response is OK');
                const responseBody = await response.json();
                setData(responseBody.content);
                setLoading(false);
                setTotalRows(responseBody.count)
            }
        } catch (error) {
            console.error('Error during get setup status:', error);
        }
    };

    return (
        <div>
            <div style={{ height: `calc(100vh - 150px)`, width: '100%' }}>
                <DataGrid
                    rowHeight={45}
                    rows={data}
                    slots={{
                        pagination: CustomPagination,
                    }}
                    columns={columns}
                    loading={loading}
                    pageSizeOptions={[10, 20, 50, 100]}
                    disableColumnSelector
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'totalOrders', sort: 'desc' }],
                        },
                        pagination: {
                            paginationModel: { pageSize: 20, page: 0 },
                        },
                    }}
                    sx={{
                        [`& .${gridClasses.menuIcon}`]: {
                            visibility: "visible",
                            width: "auto"
                        }
                    }}
                />
            </div>

            {error && errorDetails?.title &&
                <div>
                    <NavigateModal
                        title={errorDetails.title}
                        body={errorDetails.message}
                        path={errorDetails.path}
                        buttonText={errorDetails.buttonText}
                    />
                </div>
            }
        </div>
    );
}
