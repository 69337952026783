import { LineChart, MarkElement } from "@mui/x-charts";
import { eachDayOfInterval } from "date-fns";
import moment from "moment";
import './ChartWrappers.css';

export const LineChartWrapper = ({dataset, series, title}) => {

    function getDatesInRange(startDate, endDate) {
        return eachDayOfInterval({
            start: startDate,
            end: endDate
        })
    } 
    const daylist = getDatesInRange(dataset[0].dateTimestamp, dataset[dataset.length - 1].dateTimestamp);
    const slotsMark = (props) => {

        return (
            <MarkElement {...props} classes={{root: "customMarkElement"}} />
        )
    }

    return (
        <div className="chartpanel">
            <h5 className="charttitle">{title}</h5>
            <LineChart
                dataset={dataset}
                xAxis={[{ 
                    data: daylist,
                    dataKey: 'date',
                    scaleType: 'time',
                    valueFormatter: (date) => moment(date).format("DD-MM-YYYY") }]}
                series={series}
                height={400}
                slotProps={{
                    legend: {
                        direction: 'row',
                        position: { vertical: 'top', horizontal: 'right' },
                        padding: 0,
                        itemMarkWidth: 20,
                        itemMarkHeight: 2,
                        markGap: 5,
                        itemGap: 10,
                    },
                }}
                slots={{
                    mark: slotsMark,
                }}
                sx={{
                    "& .customMarkElement": {
                        scale: "0",
                    }
                }}
            />
        </div>
    );

}