import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { NavigateModal } from "../../components/modals/NavigateModal";
import { DataGrid, gridClasses, useGridApiContext, useGridSelector, GridPagination, gridPageCountSelector } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import moment from 'moment';

function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
        <MuiPagination
            color="primary"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}
        />
    );
}

function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const columns = [
    {
        renderHeader: (params) => (
            <strong>
                {'Order ID'}
            </strong>
        ),
        field: "orderId",
        headerName: 'Order Id',
        flex: 1,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
    },
    {
        field: "orderPlacedDate",
        headerName: 'Order Date',
        flex: 1,
        sortable: true,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        renderHeader: (params) => (
            <strong>
                {'Order Date'}
            </strong>
        ),
        valueFormatter: (params) => (new Date(params).toLocaleString())
    },
    {
        field: "status",
        headerName: 'Order Status',
        flex: 1,
        sortable: true,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        renderHeader: (params) => (
            <strong>
                {'Order Status'}
            </strong>
        ),
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Items'}
            </strong>
        ),
        field: "skuMiniInfoList",
        headerName: 'Items',
        valueFormatter: p => (p.map(item => `${item.sku}::${item.quantity}`).join(', ')),
        flex: 1,
        sortable: false,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: (params) => (
            <strong>
                {'COGS'}
            </strong>
        ),
        field: "costOfGoodsSold",
        headerName: 'COGS',
        sortable: false,
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Gross Revenue'}
            </strong>
        ),
        field: "grossRevenue",
        headerName: 'Gross Revenue',
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        valueFormatter: p => Math.round(p * 100) / 100
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Gross Profit'}
            </strong>
        ),
        field: "grossProfit",
        headerName: 'Gross Profit',
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        valueFormatter: p => Math.round(p * 100) / 100
    },
];

const getQueryParams = (params) => ({
    size: params.pagination?.pageSize,
    page: params.pagination?.page,
    sortField: params.sorting?.field,
    sortOrder: params.sorting?.sort,
    filterField: params.filters?.field,
    filterOperator: params.filters?.operator,
    filterValue: params.filters?.value,
    startDate: params.dates?.start,
    endDate: params.dates?.end
});

export const OrderTable = ({ userId, accessToken, dates }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorDetails, setErrorDetails] = useState()
    const [error, setError] = useState(false)
    const [totalRows, setTotalRows] = useState(100)
    const [tableParams, setTableParams] = useState({
        pagination: {
            page: 0,
            pageSize: 20,
        },
        sorting: {
            field: "grossRevenue",
            sort: "desc",
        },
        filters: {
            field: "orderId",
            operator: "isNotEmpty",
            value: ""
        },
        dates: {
            start: moment(new Date(String(dates[0]))).format('YYYY-MM-DD'),
            end: moment(new Date(String(dates[1]))).format('YYYY-MM-DD'),
        }
    });

    useEffect(() => {
        if (dates && dates[0] && dates[1]) {
            setTableParams(prev => ({
                ...prev,
                dates: {
                    start: moment(new Date(String(dates[0]))).format('YYYY-MM-DD'),
                    end: moment(new Date(String(dates[1]))).format('YYYY-MM-DD'),
                }
            }));
        }
    }, [dates]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (accessToken && userId && tableParams.dates.start) {
                fetchData(accessToken, userId);
            }
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [tableParams]);

    const fetchData = async (accessToken, userId) => {
        setLoading(true);
        try {
            const url = process.env.REACT_APP_BACKEND_URL + `/user/` + userId + `/amazon/data/orders?${qs.stringify(getQueryParams(tableParams))}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": accessToken
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                throw new Error('Unable to get status');
            } else {
                const responseBody = await response.json();
                setData(responseBody.content || []);
                setTotalRows(responseBody.totalElements || 0);
            }
        } catch (error) {
            console.error('Error during get setup status:', error);
            setData([]);
            setTotalRows(0);
        } finally {
            setLoading(false);
        }
    };

    const handlePagination = (e) => {
        setTableParams(prev => ({
            ...prev,
            pagination: {
                page: e.page,
                pageSize: e.pageSize
            }
        }));
    }

    const handleSortModelChange = React.useCallback((sortModel) => {
        setTableParams(prev => ({
            ...prev,
            sorting: sortModel[0]
                ? {
                    field: sortModel[0].field,
                    sort: sortModel[0].sort
                }
                : {
                    field: "totalRevenue",
                    sort: "desc"
                }
        }));
    }, []);

    const handleFilterChange = React.useCallback((filterModel) => {
        setTableParams(prev => ({
            ...prev,
            filters: filterModel.items[0]
                ? {
                    field: filterModel.items[0].field,
                    operator: filterModel.items[0].operator,
                    value: filterModel.items[0].value
                }
                : {
                    field: "orderId",
                    operator: "isNotEmpty",
                    value: ""
                }
        }));
    }, []);

    return (
        <div>
            <div style={{ height: `calc(100vh - 150px)`, width: '100%' }}>
                <DataGrid
                    rowHeight={45}
                    rows={data}
                    rowCount={totalRows}
                    paginationModel={tableParams.pagination}
                    paginationMode="server"
                    onPaginationModelChange={handlePagination}
                    slots={{
                        pagination: CustomPagination,
                    }}
                    columns={columns}
                    loading={loading}
                    pageSizeOptions={[10, 20, 50, 100]}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    filterMode="server"
                    onFilterModelChange={handleFilterChange}
                    disableColumnSelector
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'grossRevenue', sort: 'desc' }],
                        },
                    }}
                    sx={{
                        [`& .${gridClasses.menuIcon}`]: {
                            visibility: "visible",
                            width: "auto"
                        }
                    }}
                />
            </div>
            {error && errorDetails?.title &&
                <div>
                    <NavigateModal
                        title={errorDetails.title}
                        body={errorDetails.message}
                        path={errorDetails.path}
                        buttonText={errorDetails.buttonText}
                    />
                </div>
            }
        </div>
    );
}
