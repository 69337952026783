import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { NavigateModal } from "../../components/modals/NavigateModal";
import { DataGrid, gridClasses, useGridApiContext, useGridSelector, GridPagination, gridPageCountSelector } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <MuiPagination
            color="primary"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}
        />
    );
}

function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function EditToolbar(props) {
    const { setRows, setRowModesModel, setData } = props;

    const handleClick = () => {
        const id = Date.now();
        const newRow = {
            id,
            sku: '',
            totalOrders: 0,
            totalOrderedQuantity: 0,
            totalReturnQuantity: 0,
            cost: 0,
            totalRevenue: 0,
            totalCost: 0,
            totalProfit: 0,
            isNew: true
        };

        setRows((oldRows) => [...oldRows, newRow]);
        setData((oldData) => [...oldData, newRow]);
        
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'sku' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add SKU
            </Button>
        </GridToolbarContainer>
    );
}

const columns = [
    {
        field: "sku",
        flex: 1,
        sortable: false,
        headerName: "SKU",
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        editable: true,
        renderHeader: (params) => (
            <strong>
                {'SKU'}
            </strong>
        ),
    },
    {
        renderHeader: (params) => (
            <strong>
                {'Cost'}
            </strong>
        ),
        field: "cost",
        headerName: 'Cost',
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        editable: true,
    }
];

const getQueryParams = (params) => ({
    startDate: params.dates?.start,
    endDate: params.dates?.end
});


export const SkuDetailsTable = ({ userId, accessToken, dates }) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [errorDetails, setErrorDetails] = useState()
    const [error, setError] = useState(false)
    const [totalRows, setTotalRows] = useState(100)
    const [rowModesModel, setRowModesModel] = useState({});
    const [tableParams, setTableParams] = useState({
        dates: {
            start: moment(new Date(String(dates[0]))).format('YYYY-MM-DD'),
            end: moment(new Date(String(dates[1]))).format('YYYY-MM-DD'),
        }
    });
    const [rows, setRows] = useState([]);
    const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, id: null });
    const [pendingChanges, setPendingChanges] = useState(null);

    useEffect(() => {
        console.log('Props received:', { userId, accessToken, dates });
    }, [userId, accessToken, dates]);

    useEffect(() => {
        if (dates && dates[0] && dates[1]) {
            setTableParams(prev => ({
                ...prev,
                dates: {
                    start: moment(new Date(String(dates[0]))).format('YYYY-MM-DD'),
                    end: moment(new Date(String(dates[1]))).format('YYYY-MM-DD'),
                }
            }));
        }
    }, [dates]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (accessToken && userId && tableParams.dates.start) {
                fetchData(accessToken, userId);
            }
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [tableParams]);


    const fetchData = async (accessToken, userId) => {
        setLoading(true);
        try {
            const url = process.env.REACT_APP_BACKEND_URL + `/user/` + userId + `/amazon/data/skus?${qs.stringify(getQueryParams(tableParams))}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accessToken": accessToken
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("userId")
                    setError(true)
                    setErrorDetails({
                        title: "Session Expired",
                        message: "Please log in again, Click on logout to navigate to login screen",
                        buttonText: "Logout",
                        path: "/"
                    })
                }
                throw new Error('Unable to get status');
            }

            const responseBody = await response.json();
            setData(responseBody.content);
            setRows(responseBody.content);
            setTotalRows(responseBody.count)
        } catch (error) {
            console.error('Error during get setup status:', error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!pendingChanges) return;

        const saveChanges = async () => {
            try {
                const url = `${process.env.REACT_APP_BACKEND_URL}/user/${userId}/amazon/data/skus`;
                const method = pendingChanges.isNew ? "POST" : "PUT";
                
                const response = await fetch(url, {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        "accessToken": accessToken
                    },
                    body: JSON.stringify(pendingChanges)
                });

                if (!response.ok) {
                    throw new Error('Failed to save SKU');
                }

                // Refresh data after successful save
                fetchData(accessToken, userId);
            } catch (error) {
                console.error('Error saving changes:', error);
                // Optionally revert changes here
            } finally {
                setPendingChanges(null);
            }
        };

        saveChanges();
    }, [pendingChanges, userId, accessToken]);

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        setDeleteConfirmation({ open: true, id });
    };

    const deleteSkuFromBackend = async (skuId) => {
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/user/${userId}/amazon/data/skus/${skuId}`;
            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    "accessToken": accessToken
                }
            });

            if (!response.ok) {
                console.error('Failed to delete SKU');
                // Now fetchData is in scope
                fetchData(accessToken, userId);
            }
        } catch (error) {
            console.error('Error deleting SKU:', error);
        }
    };

    const handleDeleteConfirm = () => {
        const id = deleteConfirmation.id;
        setRows(rows.filter((row) => row.id !== id));
        setData(data.filter((row) => row.id !== id));
        setDeleteConfirmation({ open: false, id: null });
        
        // Now we can call deleteSkuFromBackend
        deleteSkuFromBackend(id);
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        // Find the row in both rows and data arrays
        const editedRow = rows.find((row) => row.id === id);
        
        // If it's a new row, remove it from both states
        if (editedRow?.isNew) {
            setRows(rows.filter((row) => row.id !== id));
            setData(data.filter((row) => row.id !== id));
        } else {
            // If it's an existing row, revert to original data
            const originalRow = data.find((row) => row.id === id);
            if (originalRow) {
                setRows(rows.map((row) => (row.id === id ? originalRow : row)));
            }
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setData(data.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setPendingChanges(updatedRow); // Trigger save useEffect
        return updatedRow;
    };

    const columnsWithActions = [
        ...columns,
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <div>
            <div style={{ height: `calc(100vh - 150px)`, width: '100%' }}>
                <DataGrid
                    rowHeight={45}
                    rows={rows}
                    slots={{
                        pagination: CustomPagination,
                        toolbar: EditToolbar,
                    }}
                    columns={columnsWithActions}
                    loading={loading}
                    pageSizeOptions={[10, 20, 50, 100]}
                    disableColumnSelector
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'totalOrders', sort: 'desc' }],
                        },
                        pagination: {
                            paginationModel: { pageSize: 20, page: 0 },
                        },
                    }}
                    sx={{
                        [`& .${gridClasses.menuIcon}`]: {
                            visibility: "visible",
                            width: "auto"
                        }
                    }}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slotProps={{
                        toolbar: { 
                            setRows, 
                            setRowModesModel,
                            setData // Pass setData to toolbar
                        },
                    }}
                />
            </div>

            <Dialog
                open={deleteConfirmation.open}
                onClose={() => setDeleteConfirmation({ open: false, id: null })}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this SKU?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setDeleteConfirmation({ open: false, id: null })}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleDeleteConfirm} 
                        color="error" 
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {error && errorDetails?.title &&
                <div>
                    <NavigateModal
                        title={errorDetails.title}
                        body={errorDetails.message}
                        path={errorDetails.path}
                        buttonText={errorDetails.buttonText}
                    />
                </div>
            }
        </div>
    );
}
