// ApiContext.js
import React, { createContext, useEffect ,useContext, useState } from 'react';

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [activeUser, setActiveUser] = useState(null);
  const [setupDetails, setSetupDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async (accessToken) => {
      try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/token", {
          headers: {
            accessToken
          }
        });
        if (!response.ok) throw new Error("Error from fetchUser API");
        const data = await response.json();
        if (data.status === "SUCCESS") {
          setActiveUser(data.data);
        } else {
          throw new Error(data.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      fetchUser(accessToken);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (activeUser) {
      const getAmazonSetupDetails = async (userId) => {
        try {
          const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/user/" + userId + "/amazon/setup", {
            method: "GET",
            headers: {
              accessToken: localStorage.getItem("accessToken")
            }
          });
          if (!response.ok) throw new Error('Unable to get setup status');
          const responseBody = await response.json();
          setSetupDetails(responseBody.data);
        } catch (error) {
          console.error('Error during get setup status:', error);
        }
      };
      getAmazonSetupDetails(activeUser.id);
    }
  }, [activeUser]);

  return (
    <ApiContext.Provider value={{ activeUser, setActiveUser, setupDetails, setSetupDetails, loading, setLoading  }}>
      {children}
    </ApiContext.Provider>
  );
};
export const useApi = () => {
    return useContext(ApiContext);
};