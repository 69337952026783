import { BarChart } from "@mui/x-charts";
import { eachDayOfInterval } from "date-fns";
import moment from "moment";
import './ChartWrappers.css';

export const BarChartWrapper = ({dataset, series, title}) => {

    function getDatesInRange(startDate, endDate) {
        return eachDayOfInterval({
            start: startDate,
            end: endDate
        })
    } 
    const daylist = getDatesInRange(dataset[0].dateTimestamp, dataset[dataset.length - 1].dateTimestamp);

    return (
        <div className="chartpanel">
            <h5 className="charttitle">{title}</h5>
            <BarChart
                dataset={dataset}
                xAxis={[{ 
                    data: daylist,
                    dataKey: 'date',
                    scaleType: 'band',
                    valueFormatter: (date) => moment(date).format("DD-MM-YYYY") }]}
                series={series}
                height={400}
                slotProps={{
                    legend: {
                        direction: 'row',
                        position: { vertical: 'top', horizontal: 'right' },
                        padding: 0,
                        itemMarkWidth: 20,
                        itemMarkHeight: 2,
                        markGap: 5,
                        itemGap: 10,
                    },
                }}
            />
        </div>
    );

}